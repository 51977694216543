<template>
  <!-- <v-parallax height="2044" :src="require('./assets/background.png')"> -->

  <v-app>

    <v-app-bar app flat color="transparent" height="60" class="d-flex align-center justify-start">
      <div>
        <v-img alt="Firefly Logo" class="shrink mt-" contain :src="require('./assets/logo.svg')"
          transition="scale-transition" />
      </div>
    </v-app-bar>

    <v-main class="pt-0">
      <Home />
    </v-main>

    <v-footer color="transparent" padless>
      <v-container class="pa-0">
        <v-row class="ma-0">
          <v-col cols="12" class="pa-0 ma-0">
            <v-img :src="require('./assets/footerBg.png')" height="683" class="footer-bg" position="top" transition="fade-transition" cover>
              <v-row no-gutters class="pa-0">

                <v-col cols="5" class="firefly-links">
                  <v-img :src="require('./assets/firefly_logo.svg')" class="mx-auto" width="395"></v-img>

                  <p class="text-center pt-8">Want to be a Fireflyer? Apply here:</p>
                  <v-row class="justify-center">
                    <v-col cols="4" class="d-flex justify-center">
                      <v-btn href="https://firefly.staffed.it/registration" target="blank" dark class="staff-btn mx-4"
                        outlined color="#D10990" large>New Staff
                      </v-btn>
                    </v-col>
                    <v-col cols="4" class="d-flex justify-center">
                      <v-btn href="https://firefly.staffed.it/staffarea" target="blank" dark outlined color="#D10990"
                        class="staff-btn mx-4" large>Existing Staff
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="3" class="address">
                  <div>
                    <hr color="#D10990" />
                    <p class="mt-1 mb-1">
                      Address:<br />
                      St. John's Hall<br />
                      374 North End Road<br />
                      London<br />
                      SW6 1LY
                    </p>
                    <hr color="#D10990"/>
                  </div>
                  <div>
                    <p class="mt-1 mb-1">
                      Phone Number:<br />
                      <a href="tel:+442073856677">0207 385 6677</a>
                    </p>
                    <hr color="#D10990"/>
                  </div>
                  <div>
                    <p class="mt-1 mb-1">
                      Email:<br />
                      <a href="mailto:newbiz@firefly-staffing.com">newbiz@firefly-staffing.com</a><br />
                    </p>
                    <hr color="#D10990"/>
                  </div>
                  <div>
                    <p class="mt-1 mb-0">Socials:</p>
                    <v-btn icon href="https://www.facebook.com/fireflystaffing/" target="blank"
                      class="ml-0 mr-2 white--text" large mdi-facebook>
                      <v-icon size="42px">mdi-facebook</v-icon>
                    </v-btn>
                    <v-btn icon href="https://www.instagram.com/fireflystaffing/" target="blank"
                      class="mx-2 white--text" large mdi-instagram>
                      <v-icon size="42px">mdi-instagram</v-icon>
                    </v-btn>
                    <v-btn icon href="https://www.linkedin.com/company/firefly-marketing-uk/" target="blank"
                      class="mx-2 white--text" large mdi-linkedin>
                      <v-icon size="42px">mdi-linkedin</v-icon>
                    </v-btn>
                    <hr class="mt-1" color="#D10990" />
                  </div>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
      </v-container>

    </v-footer>

  </v-app>
  <!-- </v-parallax> -->

</template>

<script>
import Home from './components/Home';

export default {
  name: 'App',

  components: {
    Home,
  },
};
</script>

<style lang="scss">
body {
  background-color: #CD409B !important;
  /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, #CD409B, #000000) !important;
  font-family: "Oswald", Roboto, Arial, Helvetica, sans-serif !important;
  color:#ffffff!important;

  p{
    color:#ffffff;
  }

  a {
    color:#ffffff;
    text-decoration: none;
  }
}

.v-application {
  background: none !important;
  max-width: 1185px;
  width: 100%;
  margin: 0 auto;

  .text-h1,
  .text-h2,
  .text-h3,
  .text-h4,
  .text-h5,
  .text-h6 {
    font-family: "Oswald", Roboto, Arial, Helvetica, sans-serif !important;
  }
}

.v-toolbar {
  position: relative !important;
}

.v-footer {
  max-width: 1185px;
}

a.staff-btn {
  background: rgba(0,0,0, 0.3);
  border-radius: 10px;
}

.footer-bg {
  padding:140px 0 0 0;
  @media only screen and (max-width: 740px) {
    padding:0 0 0 0;
  }

}

.firefly-links {
  min-width: 430px!important;
  margin: 0 auto;
  padding-bottom: 30px!important;
}

.address {
  p a {
    color: #fff;
  }
  margin: 0 auto!important;
  min-width: 296px!important;
}

</style>
